<template>
  <div class="">
   <Header />
   <div class="mx-auto max-w-screen-lg px-3 lg:max-w-screen-2xl lg:px-8 xl:max-w-screen-xl xl:px-4 pt-4">
      <breadcrumb></breadcrumb>
      <div class="flex gap-7 pb-10 pt-4">
         <div class="w-1/5">
            <Sidebar/>
         </div>
         <div class="w-4/5">
            <router-view />
         </div>
      </div>
   </div>
   <Footer />
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import Footer from '../../components/Footer.vue';
import Sidebar from './components/Sidebar.vue';

export default {
   name: 'Dana',
   props: {
      subPage: {
         type: String,
         default: 'Transactions',
         required: false,
      },
   },
   components: {
      Header,
      Breadcrumb,
      Sidebar,
      Footer,
   },
};
</script>
