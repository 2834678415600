<template>
  <aside class="col-span-5 md:col-span-1 p-2 xl:p-5 w-full flex flex-col justify-between bg-white transition duration-300 shadow-sidebar rounded-10">
    <div class="">
      <div class="flex space-x-3 items-center justify-start">
        <div class="my-2">
          <img src="@/assets/logo-dana.png" class="w-12" />
        </div>
        <span class="text-1a font-semibold text-xl">DANA</span>
      </div>
      <div class="flex space-x-3 items-center justify-start">
        <div class="w-12">
        </div>
        <div class="flex flex-col">
          <span class="text-1a font-semibold">Balance</span>
          <span class="text-1a font-bold text-xl">{{ saldo.balance | toCurrency }}</span>
        </div>
      </div>
      <div class="flex flex-row w-full justify-between">
        <div class="mt-8 flex flex-col gap-4">
          <div class="flex items-center gap-2 cursor-pointer" :class="[isActive === 'DanaTransactionHistory' ? 'text-primary' : 'text-black',]">
            <font-awesome-icon :icon="['fas', 'receipt']" size="xl" />
            <router-link
              :to="{'name': 'DanaTransactionHistory'}"
              :class="[
                'font-semibold text-base hover:text-secondary',
              ]"
              >Transaction History</router-link
            >
          </div>
          <div class="flex items-center gap-2 cursor-pointer" :class="[isActive === 'DanaTopup' ? 'text-primary' : 'text-black']">
            <div>
              <svg data-name="Icon feather-plus-square" role="img" xmlns="http://www.w3.org/2000/svg" width="21.324" height="21.324" viewBox="0 0 21.324 21.324">
                  <path data-name="Path 12090" fill="currentColor" d="M3.072 0H18.25a3.076 3.076 0 0 1 3.072 3.072V18.25a3.076 3.076 0 0 1-3.072 3.074H3.072A3.076 3.076 0 0 1 0 18.25V3.072A3.076 3.076 0 0 1 3.072 0zM18.25 19.517a1.266 1.266 0 0 0 1.267-1.267V3.072a1.266 1.266 0 0 0-1.267-1.265H3.072a1.266 1.266 0 0 0-1.265 1.265V18.25a1.266 1.266 0 0 0 1.265 1.265z"/>
                  <path data-name="Path 12091" fill="currentColor" d="M10.662 15.903a.9.9 0 0 1-.9-.9V6.326a.903.903 0 1 1 1.807 0V15a.9.9 0 0 1-.907.903z"/>
                  <path data-name="Path 12092" fill="currentColor" d="M14.999 11.566H6.325a.903.903 0 1 1 0-1.807h8.674a.903.903 0 1 1 0 1.807z"/>
              </svg>
            </div>
            <router-link
              :to="{'name': 'DanaTopup'}"
              :class="[
                'font-semibold text-base hover:text-secondary',
              ]"
              >Top Up</router-link
            >
          </div>
          <div class="flex items-center gap-2 cursor-pointer">
            <div>
              <svg data-name="Icon Unlink" xmlns="http://www.w3.org/2000/svg" width="21.324" height="21.324" viewBox="0 0 21.324 21.324">
                <g data-name="Group 8077">
                    <path data-name="Path 12240" d="M4.156 14.058a3.435 3.435 0 0 1 1.026-2.416q.783-.792 1.575-1.576a.692.692 0 1 1 .983.974c-.525.531-1.055 1.055-1.581 1.585a2.028 2.028 0 0 0-.614 1.487 2.007 2.007 0 0 0 1.607 1.985 2.081 2.081 0 0 0 1.978-.575c.527-.512 1.041-1.039 1.563-1.557a.691.691 0 1 1 .971.984c-.362.367-.728.73-1.093 1.094-.168.168-.335.338-.506.5a3.5 3.5 0 0 1-5.054-.17 3.423 3.423 0 0 1-.855-2.315z"/>
                    <path data-name="Path 12241" d="M17.545 7.568a3.467 3.467 0 0 1-1 2.463c-.531.545-1.073 1.078-1.613 1.614a.669.669 0 0 1-.716.176.653.653 0 0 1-.462-.525.686.686 0 0 1 .216-.648c.509-.506 1.014-1.015 1.522-1.521a2.254 2.254 0 0 0 .471-.654 2.042 2.042 0 0 0-1.609-2.9 2.071 2.071 0 0 0-1.771.593c-.522.507-1.033 1.027-1.546 1.543a.694.694 0 1 1-1.006-.954c.4-.407.807-.814 1.217-1.215a7.685 7.685 0 0 1 .739-.68 3.48 3.48 0 0 1 4.311.09 3.358 3.358 0 0 1 1.247 2.618z"/>
                    <path data-name="Path 12242" d="M13.501 8.875a.711.711 0 0 1-.2.51c-.376.378-.751.757-1.131 1.131a.456.456 0 0 1-.675 0 8.429 8.429 0 0 1-.313-.314.448.448 0 0 1-.008-.642c.383-.392.767-.782 1.164-1.16a.665.665 0 0 1 .774-.119.65.65 0 0 1 .389.594z"/>
                    <path data-name="Path 12243" d="M8.198 12.828a.7.7 0 0 1 .2-.5q.567-.572 1.139-1.139a.456.456 0 0 1 .674 0c.1.1.206.2.306.306a.452.452 0 0 1 .008.659 55.6 55.6 0 0 1-1.14 1.138.676.676 0 0 1-.781.136.648.648 0 0 1-.406-.6z"/>
                    <path data-name="Path 12244" d="M5.333 5.067a1.273 1.273 0 0 1 .188.13Q6.553 6.223 7.58 7.253c.061.061.123.121.183.184a.232.232 0 1 1-.327.326q-.713-.71-1.423-1.423c-.281-.28-.562-.56-.841-.842a.231.231 0 0 1 0-.359.859.859 0 0 1 .161-.072z"/>
                    <path data-name="Path 12245" d="M16.649 16.395c-.058.075-.093.16-.153.186a.445.445 0 0 1-.233 0c-.033-.006-.061-.048-.088-.076q-1.106-1.1-2.208-2.213a.373.373 0 0 1-.107-.222.22.22 0 0 1 .337-.182.51.51 0 0 1 .1.083l2.2 2.2a1.567 1.567 0 0 1 .152.224z"/>
                    <path data-name="Path 12246" d="M15.934 13.622h-1.071a.887.887 0 0 1-.119 0 .228.228 0 0 1-.014-.451.683.683 0 0 1 .129-.008h2.131a.663.663 0 0 1 .139.01.227.227 0 0 1-.018.448.8.8 0 0 1-.118.005z"/>
                    <path data-name="Path 12247" d="M13.619 15.941v1.114a.234.234 0 0 1-.23.26.24.24 0 0 1-.232-.26v-2.241a.237.237 0 0 1 .178-.262.231.231 0 0 1 .284.251c.002.381.001.76 0 1.138z"/>
                    <path data-name="Path 12248" d="M8.544 5.781v1.1c0 .165-.082.264-.217.273a.224.224 0 0 1-.24-.188.71.71 0 0 1-.006-.119V4.595a.228.228 0 0 1 .452-.02 1.115 1.115 0 0 1 .009.183c.002.345.002.684.002 1.023z"/>
                    <path data-name="Path 12249" d="M5.774 8.546H4.703a.656.656 0 0 1-.129-.009.228.228 0 0 1 .018-.45.628.628 0 0 1 .086 0h2.2c.162 0 .256.065.277.186a.232.232 0 0 1-.252.276h-.8z"/>
                </g>
                <g data-name="Icon feather-plus-square">
                    <path data-name="Path 12090" d="M3.072 0H18.25a3.076 3.076 0 0 1 3.072 3.072V18.25a3.076 3.076 0 0 1-3.072 3.074H3.072A3.076 3.076 0 0 1 0 18.25V3.072A3.076 3.076 0 0 1 3.072 0zM18.25 19.517a1.266 1.266 0 0 0 1.267-1.267V3.072a1.266 1.266 0 0 0-1.267-1.265H3.072a1.266 1.266 0 0 0-1.265 1.265V18.25a1.266 1.266 0 0 0 1.265 1.265z"/>
                </g>
              </svg>
            </div>
            <a
              @click="openModal = true"
              :class="[
                'font-semibold text-base hover:text-secondary',
                'text-black',
                'cursor-pointer',
              ]"
              >Unlink</a>
          </div>
        </div>
      </div>
    </div>
    <modal-unlink :dialog="openModal" @handle-unlink="handleUnlinkDana"></modal-unlink>
  </aside>
</template>

<script>

export default {
  name: "Sidebar",
  data: () => ({
    openModal: false,
    showDetail: false,
    icTopup: require("@/assets/icons/ic-topup.svg"),
    icTopupAc: require("@/assets/icons/ic-topup-active.svg"),
    icTf: require("@/assets/icons/ic-tf.svg"),
    icTfAc: require("@/assets/icons/ic-tf-active.svg"),
    icTrans: require("@/assets/icons/ic-trans.svg"),
    icTransAc: require("@/assets/icons/ic-trans-active.svg"),
    icSet: require("@/assets/icons/ic-setting.svg"),
    icSetAc: require("@/assets/icons/ic-setting-active.svg"),
    icTriangle: require("@/assets/icons/ic-triangle.svg"),
  }),
  components: {
    ModalUnlink: () => import('./ModalUnlink.vue'),
  },
  methods: {
    detailSaldo() {
      this.showDetail = !this.showDetail;
    },
    async handleUnlinkDana(val){
      this.openModal = false
      if(!val) return

      try {
        const response = await this.$http.post(
          `${this.$apiTripweWeb}/dana/accounts/unbinding`
        );
        if (response.data.status != 200) {
          throw response.data.message;
        }
        this.$store.commit('setConnectDana', false)
        this.$store.commit('setSaldoDana', {})
        this.$router.push({name: 'Home'})
      } catch (error) {
        this.$toasted.global.error(error);
      }
      return
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    saldo() {
      return this.$store.state.saldoDana;
    },
    isActive() {
      return this.$route.name;
    },
  },
};
</script>
